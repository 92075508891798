.rtc .ant-modal-header .title .icon {
    margin-right: 1rem;
}

.rtc .ant-modal-body {
    padding: 0.5rem 0;
}

.rtc .content .tab .title .icons .audio {
    font-size: 16px;
    margin-right: 0.5rem;
}

.rtc .content .tab .title .icons .video {
    font-size: 16px;
    margin-right: 0.5rem;
}

.rtc .content .tab .title .icons .right {
    font-size: 16px;
}

.rtc .content .tab .title .icons .sender {
    color: #ff4d4f;
}

.rtc .content .tab .title .icons .receiver {
    color: #73d13d;
}

.rtc .content .tab .content {
    background: #f5f5f5;
    margin-right: 1rem;
    border-radius: 2px;
    padding: 1rem 1rem 0 1rem;
}

.rtc .content .tab .content .paragraph {
    /* padding: 0.5rem 1rem 0.5rem 1rem; */
}
.rtc .content .tab .content .sub-paragraph {
    /* padding: 0 1rem; */
}
.rtc .content .tab .content .graph {
    /* padding: 0 1rem; */
}