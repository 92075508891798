.icon-svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    overflow: hidden;
}

.show {
    opacity: 1;
    height: 100%;
    transform: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.hidden {
    opacity: 0;
    height: 0;
    transform: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.yes,
.online,
.allow,
.granted,
.true .ant-descriptions-item-content,
.enable .ant-descriptions-item-content,
.granted .ant-descriptions-item-content,
.good .ant-descriptions-item-content {
    color: #73d13d;
}

.just-so-so .ant-descriptions-item-content {
    color: #ffa940;
}

.no,
.none,
.offline,
.forbidden,
.denied,
.bad,
.denied .ant-descriptions-item-content,
.forbidden .ant-descriptions-item-content,
.false .ant-descriptions-item-content,
.disable .ant-descriptions-item-content,
.bad .ant-descriptions-item-content {
    color: #ff4d4f;
}

.screen-force .ant-btn-dangerous:focus,
.screen-force .ant-btn-dangerous:hover,
.screen-force .ant-btn-dangerous:active,
.screen-force .ant-btn-dangerous{
    background: #ff4d4f;
}

.screen-force .ant-btn-dangerous > span {
    color: #fff;
}

.content .descriptions {
    /* margin-left: 2rem; */
}

.content .descriptions .ant-descriptions-row > td {
    padding-bottom: 0;
}

.content .descriptions .ant-descriptions-item-label {
    width: 5rem;
}

.content .title {
    display: flex;
    align-items: center;
}

.content .space-between {
    justify-content: space-between;
}

.content .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 0;
}

.content .title .icon {
    font-size: 16px;
    margin-bottom: 0.5em;
    line-height: 1.4;
    margin-right: 0.5rem;
}


.content .input-wrapper {
    display: flex;
    margin: 1rem 0;
}

.content .input-wrapper .input {
    border-radius: 2px 0 0 2px;
    width: 100%;
    letter-spacing: 2px;
}

.content .input-wrapper .danger {
    border-color: #ff4d4f;
}

.content .input-wrapper .input .ant-input-number-handler-wrap {
    display: none;
}

.content .input-wrapper .input:focus {
    box-shadow: none;
}

.content .input-wrapper .ok {
    border-radius: 0 2px 2px 0;
    font-weight: 500;
    box-shadow: none;
}

.content .operation {
    display: flex;
    justify-content: flex-end;
}

.content .operation .btn-more {
    padding: 4px 0;
}

.content .exist-wrapper {
    display: inline-block;
}

.content .exist-wrapper .tip {
    color: #ff4d4f;
    margin-bottom: 0.5rem;
}

.content .exist-wrapper .buttons {
    position: relative;
    float: right;
}

.content .ant-list-item-meta {
    margin-bottom: 0.5rem;
}

.content .ant-list-vertical .ant-list-item-action {
    margin-top: 0;
}

.content .actions {
    /* margin-left: 2rem; */
    .ant-space-item {
        // padding: 0 8px;
        .ant-btn {
            padding: 0 8px 0 0;
        }
        :hover {
            // background-color: #bae7ff;
        }
    }
    
}

/* 会议选择框（选创建、选入会） */
.wizzard .content {
    .description {
        font-size: 20px;
        user-select: none;
    }
    .select {
        .icon {
            margin: 2rem 0;
            font-size: 100px;
        }

        .wrapper {
            text-align: center;
            display: flex;
            flex-direction: column;
            padding: 1rem 1rem 3rem 1rem;

            &:hover {
                cursor: pointer;
                background-color: #e6f7ff;
                border-radius: 2px;
                // transition: all 0.3s cubic-bezier(.7,.3,.1,1);
                .description {
                    color: #1890ff;
                }
            }
        }
    }
}
    

/* 权限帮助 */
.permission-help {
    .ant-modal-body {
        padding: 0;
    }

    .content {
        padding: 1rem;

        .help-enable-video {
            width: 100%;
            height: 100%;
            user-select: none;
        }

        .ant-carousel .slick-dots {
            li.slick-active button {
                background-color: #ff4d4f;
            }

            li button {
                background-color: #000;
                height: 0.5rem;
            }
        }

        .ant-tabs-tab {
            padding: 0 12px 12px 12px;
        }

        .ant-tabs-tabpane .slick-btn {
            position: absolute;
            z-index: 1000;

            font-size: 32px;
            opacity: 0.5;
            transition: all 0.3s cubic-bezier(.7,.3,.1,1);

            &:hover {
                opacity: 0.9;
                transform: scale(1.3, 1.3);
            }

            &.left {
                top: 50%;
                left: 2rem;
            }

            &.right {
                top: 50%;
                right: 2rem;
            }
        }
    }
}

/* 创建会议框 */


/* 加入会议框 */
.wizzard-wrap,
.create-wrap,
.join-wrap,
.report-wrap {
    z-index: 0!important;
}

.join-wrap {
    .join {
        .ant-modal-body {
            padding: 24px 0;

            .content {
                .title,
                .input-wrapper {
                    padding: 0 24px;
                }

                .history-collapse,
                .options-collapse {
                    background: transparent;
                    border-radius: 2px;
                    border: 0px;
                    overflow: hidden;

                    .header {
                        display: inline-flex;
                        justify-content: flex-end;
                    }
                    
                    .btn-more {
                        padding: 4px 0;
                    }

                    .ant-collapse-header {
                        padding: 0 24px;
                        text-align: start;

                        .ant-collapse-arrow {
                            left: 0;
                            right: 0;
                            text-align: start;
                            position: unset;
                        }

                        .panel-title {
                            padding: 0;
                        }
                    }

                    .ant-collapse-item {
                        border-bottom: 0;
                    }

                    .ant-collapse-content {

                        max-height: 450px;
                        overflow-x: hidden;
                        overflow-y: auto;

                        .options {
                            padding: 0 24px;
                            display: flex;
                            flex-direction: column;
                            .ant-checkbox-wrapper {
                                margin: 8px 0;
                            }

                            .loudspeaker {
                                display: flex;
                                align-items: center;

                                .ant-checkbox-wrapper {
                                    width: 200px;
                                }

                                .ant-slider {
                                    width: 300px;
                                }

                            }
                        }

                        .ant-list-item {
                            padding: 12px;
                            margin: 0 12px;
                        }
                        .title {
                            padding: 0;
                        }

                        ::-webkit-scrollbar {
                            display: none;
                        }
                    }

                    .ant-collapse-content-box {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.create,
.report,
.join {
    .content {
        .title {
            .description{
                margin-bottom: 0;
            }

            .operation {
                .ant-btn {
                    padding: 0;
                }
            }
        }

        
    }
}  

.join .content .ant-list {
    padding: 0.5rem 0;
}

.join .content .ant-list-split .ant-list-item {
    border-bottom: 0;
    position: relative;
}

.join .content .ant-list-split .ant-list-item::after {
    content: "";
    width: calc(100% - 24px);
    height: 1px;
    border-bottom: 1px dashed #dfdfdf;
    border-width: thin;
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 12px;
}

.join .content .ant-list-split .ant-list-item:last-child::after {
    border-bottom: 0;
}

.join .content .ant-list .ant-list-item {
    padding: 0.5rem;
}

.join .content .ant-list .ant-list-item:hover {
    /* cursor: pointer; */
    background-color: #e6f7ff;
    border-radius: 2px;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta {
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title {
    font-weight: 500;
    /* font-family: 'Times New Roman', Times, serif; */
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .icon {
    margin-right: 1rem;
    font-size: 16px;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .code:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: underline;
}

.join .content .ant-list .ant-list-item .ant-list-item-meta .title .time-col {
    text-align: end;
}

.join .content .ant-list .ant-list-item .ant-list-item-action .icon {
    margin-left: 2px;
}

.join .content .ant-list .ant-list-item .operation-icon {
    font-size: 24px;
}

.report .content .collapse {
    background: transparent;
    border-radius: 2px;
    border: 0px;
    overflow: hidden;
}

.report .content .collapse .btn-more {
    padding: 4px 0;
}

.report .content .collapse .header {
    display: inline-flex;
    justify-content: flex-end;
}

.report .content .collapse .ant-collapse-header {
    text-align: start;
    padding: 0;
    /* width: 100%; */
}

.report .content .collapse .ant-collapse-header .ant-collapse-arrow {
    left: 0;
    right: 0;
    text-align: start;
    position: unset;
}

.report .content .collapse .ant-collapse-item {
    border-bottom: 0;
}

.report .content .collapse .ant-collapse-content {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
}

.report .content .collapse .ant-collapse-content::-webkit-scrollbar {
    display: none;
}

.report .content .collapse .ant-collapse-content-box {
    padding: 0;
}

.report .content .ant-list {
    padding: 0.5rem 0;
}

.report .content .ant-list-split .ant-list-item {
    border-bottom: 0;
    position: relative;
}

.report .content .ant-list-split .ant-list-item::after {
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #dfdfdf;
    border-width: thin;
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.report .content .ant-list-split .ant-list-item:last-child::after {
    border-bottom: 0;
}

.report .content .ant-list .ant-list-item {
    padding: 0.5rem;
}

.report .content .ant-list .ant-list-item:hover {
    /* cursor: pointer; */
    background-color: #e6f7ff;
    border-radius: 2px;
}

.report .content .ant-list .ant-list-item .ant-list-item-meta {
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.report .content .ant-list .ant-list-item .ant-list-item-meta .title {
    font-weight: 500;
    /* font-family: 'Times New Roman', Times, serif; */
}

.report .content .ant-list .ant-list-item .ant-list-item-meta .title .icon {
    margin-right: 1rem;
    font-size: 16px;
}

.report .content .ant-list .ant-list-item .ant-list-item-meta .title .code:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: underline;
}

.report .content .ant-list .ant-list-item .ant-list-item-meta .title .time-col {
    text-align: end;
}

.report .content .ant-list .ant-list-item .ant-list-item-action .icon {
    margin-left: 2px;
}

.report .content .ant-list .ant-list-item .operation-icon {
    font-size: 24px;
}

/* 挂断对话框 */
.hangup .content .title .icon {
    font-size: 20px;
    /* vertical-align: middle; */
    margin-bottom: 0.25rem;
    color: #ff4d4f;
}

/* 延时提示message */
.call-duration-expire {

}

.call-duration-expire .content .description,
.call-duration-expire .content {
    display: inline-flex;
    align-self: center;
}

.call-duration-expire .content .ant-statistic {
    align-self: center;
}

.call-duration-expire .content .ant-statistic-content {
    font-size: 14px;
    color: #ff4d4f;
}

.call-duration-expire .content .btns {
    margin-left: 4rem;
}

.call-duration-expire .content .btns .btn {
    padding: 0 0 0 0.5rem;
}


.force-warn-warpper {
    /* position: absolute; */
    /* background: #bfbfbf; */
    width: 100%;
    height: 100%;

    /* border: 1px solid red; */
}

.force-warn {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
    z-index: 1; */

    /* border-radius: 2px; */
    /* background: #bfbfbf2b; */
    padding: 1rem;
}

.force-warn .title {
    display: flex;
    cursor: default;
}


.force-warn .buttons {
    margin-top: 1rem;
}

.force-warn .warning {
    color: #ff4d4f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.force-warn .warning .permission-icon {
    cursor: default;
}

.force-warn .warning .anticon {
    font-size: 64px;
    margin-right: 0.5rem;
    vertical-align: -0.25em;

    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.force-warn .ant-descriptions-header {
    margin-right: 2rem;
}

.force-warn .ant-descriptions-view .anticon {
    vertical-align: -0.1.2em;
}

.force-warn .ant-descriptions-item-container {
    align-items: baseline;
}

.force-warn .ant-descriptions-item-label .anticon,
.force-warn .ant-descriptions-item-content .anticon {
    margin: 0 0.5rem;
    line-height: 32px;
    margin-right: 0.5rem;
    user-select: none;
    cursor: default;
}

.force-warn .warning {
    padding: 1rem;
}

.force-warn .warning .title {
    font-size: 36px;
    line-height: 42px;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.force-warn .ant-btn {
    padding: 0;
}

.force-warn .ant-btn > .anticon + span {
    margin-left: 0;
    line-height: 32px;
}

.force-warn .ant-descriptions-item-label,
.force-warn .ant-descriptions-item-content {
    /* color: #fff; */
    line-height: 32px;
    cursor: default;
}

.qrcode {
    /* text-align: center; */
}

.menu-item {
    .anticon {
        margin-right: 12px;
    }
}
@primary-color: #21f679;