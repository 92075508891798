/* 背景 */
.meet {
    .background {
        opacity: 1;
        position: relative;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #262626; 
        background-image: #262626;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        overflow: hidden;
    }

    .no-stream-tips {
        position: absolute;
        background: #262626;
        height: 100%;
        width: 100%;
        z-index: 2;
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        justify-content: center;

        + .show {
            opacity: 1!important;
            transition: all 0.3s cubic-bezier(.7,.3,.1,1);
        }

        + .hidden {
            opacity: 0!important;
            transition: all 0.3s cubic-bezier(.7,.3,.1,1);
        }

        .icons {
            position: relative;
        }

        .icon-forbidden,
        .icon-camera-off {
            font-size: 48px;
            user-select: none;
            cursor: default;
        }

        .icon-forbidden {
            font-size: 96px;
            position: absolute;
            left: -24px;
            top: -24px;
        }

        .description {
            margin-top: 2rem;
            text-align: center;
            padding: 0 1rem;
            color: #434343;
            font: bold 64px/100% "微软雅黑", "Lucida Grande", "Lucida Sans", Helvetica, Arial, Sans;
            text-shadow: 0 0 5px #141414;
            user-select: none;
            cursor: default;
        }
    }

    .local,
    .remote {
        &-no-stream-tips {
            position: absolute;
            background: #262626;
            height: 100%;
            width: 100%;
            z-index: 2;
        
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            justify-content: center;

            &.show1 {
                opacity: 1!important;
                transition-property: width, heigh, opacity;
                transition-duration: 0s, 0s, .3s;
                transition-timing-function: cubic-bezier(.7,.3,.1,1);
            }

            &.hidden1 {
                opacity: 0!important;
                transition-property: width, heigh, opacity;
                transition-duration: 0s, 0s, .3s;
                transition-timing-function: cubic-bezier(.7,.3,.1,1);
            }

            .icons {
                position: relative;
            }

            .icon-forbidden,
            .icon-camera-off {
                font-size: 24px;
                user-select: none;
                cursor: default;
            }

            .icon-forbidden {
                font-size: 48px;
                position: absolute;
                left: -12px;
                top: -12px;
            }

            .description {
                line-height: 1.5;
                height: 48px;
                text-align: center;
                padding: 1rem;
                color: #434343;
                font: bold 32px/100% "微软雅黑", "Lucida Grande", "Lucida Sans", Helvetica, Arial, Sans;
                text-shadow: 0 0 5px #141414;
                user-select: none;
                cursor: default;
            }
        }
    }

    .report-tips {
        position: absolute;
        background: #262626;
        height: 100%;
        width: 100%;
        z-index: 0;
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
    
        &.show {
            opacity: 1!important;
            transition: none;
        }
    
        &.hidden {
            opacity: 0!important;
            transition: none;
        }
    
        .icons {
            position: relative;
        }
    
        .icon-camera-off {
            font-size: 48px;
            user-select: none;
            cursor: default;
        }
    
        .icon-forbidden {
            user-select: none;
            cursor: default;
            font-size: 96px;
            position: absolute;
            left: -24px;
            top: -24px;
        }
    
        .description {
            text-align: center;
            padding: 0 1rem;
            margin-top: 2rem;
            color: #434343;
            font: bold 64px/100% "微软雅黑", "Lucida Grande", "Lucida Sans", Helvetica, Arial, Sans;
            text-shadow: 0 0 5px #141414;
            user-select: none;
            cursor: default;
        }
    }
}


/* 浮动小窗 */
.meet .float-window {
    position: absolute;

    opacity: 1;
    z-index: 400;

    background: #222222;

    border-radius: 2px;
    overflow: hidden; 
}

/* 预览 video */
.meet .local-preview .video  {
    border: none;
}

.meet .local-preview .video:hover {
    box-shadow: none;
}


/* 本端 video */
.meet .local {
    top: 4rem;
    left: 1rem;
    width: 320px;
    height: 240px;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .remote {
    top: 4rem;
    right: 1rem;
    width: 320px;
    height: 240px;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 本端hover后显示边框阴影 */
.meet .remote:hover,
.meet .local:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
    opacity: 1;
}

/* 本端的菜单 */
.meet .remote .menu .operation,
.meet .local .menu .operation{
    flex-direction: column;
}

.meet .remote .menu:hover,
.meet .local .menu:hover {
    background-color: rgba(0,0,0,0.4);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* 本端hover后显示边框阴影 */
.meet .board:hover {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
    opacity: 1;
}

.meet .board .menu:hover {
    background-color: rgba(0,0,0,0.4);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* 本端video框 */
.meet .remote .video,
.meet .local .video {
    border: none;
}

/* 电子白板框 */
.meet .board {
    top: 4rem;
    right: 1rem;
    width: 320px;
    height: 240px;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* video框 */
.meet .video {
    z-index: 1;
    border: 1px solid #ffffff7f;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* video框hover后 */
.meet .video:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
    opacity: 1;
}

/* video框上菜单操作 */
.meet .menu {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 202;
}

/* video框上菜单下操作的div层*/
.meet .menu .operation  {
    width: 100%;
    height: 100%;
    opacity: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.meet .menu .operation .col {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 4rem;
}

/* video框上菜单，当hover后操作的div层（显示出来） */
.meet .menu:hover .operation {
    opacity: 1;
    z-index: 500;
}

/* video框上菜单下操作的icon */
.meet .menu .icon {
    opacity: 0.65;
    font-size: 2rem;
    border-radius: 50%;
}

/* video框上菜单下操作的icon */
.meet .menu .icon:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.2, 1.2);
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

/* video框上菜单下操作的icon说明 */
.meet .menu .description  {
    margin-top: 0.5rem;
    color: #fff;
    user-select: none; 
}

/* video框上菜单下的终端昵称 */
.meet .menu .nickname {
    position: absolute;

    padding: 0 0.5rem;

    width: fit-content;
    height: 2rem;
    line-height: 2rem;

    /* 位置 */
    float: right;
    bottom: 1rem;
    right: 1rem;

    border-radius: 2px;

    background-color: rgba(255, 255, 255, 0.65);
    color: #262626;

    /* 字体溢出省略 */
    overflow: hiddden;
    text-overflow: ellipsis;
    white-space: nowrap;

    display: flex;
    align-items: center;

    transition: height 0.5s ease-in-out 2s;
}



/* 显示（使用动画） */
.meet .show {
    opacity: 1!important;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 隐藏（使用动画） */
.meet .hidden {
    opacity: 0!important;
    height: 0!important;
    width: 0!important;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

/* 没有数据（视频或电子白板）时显示的蒙层 */
.meet .obscuration {
    position: absolute;
    z-index: 200;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    top:50%;
    transform:translateY(-50%);

    text-align: center;
    transform-origin: center center;
    background-color: #262626;
}

/* 没有数据（视频或电子白板）时显示的封面 */
.meet .poster {
    position: absolute;
    display: inline-block;
    z-index: 201;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    top:50%;
    transform:translateY(-50%);

    text-align: center;
    transform-origin: center center;
}

/* 远端视频滑窗布局 */
.meet .remotes-warpper {
    position: absolute;
    bottom: 4rem;
    left: 0;
    z-index: 203;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    border-radius: 2px;
    margin: 0 0.5rem;

    background-color: #2222227F;
}

.meet .remotes-warpper .remotes {
    display: block;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);

    padding: 0.5rem;
}

.meet .remotes-warpper .remotes .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.meet .remotes-warpper .remotes .left .icon {
    margin: 1rem 0;
    font-size: 2rem;
    opacity: 0.65;
    color: #ffffff;
    border-radius: 50%;
}

.meet .remotes-warpper .remotes .left .description {
    color: #ffffff;
}

.meet .remotes-warpper .remotes .right {
    text-align: center;
}

.meet .remotes-warpper .remotes .right .icon {
    font-size: 2rem;
    opacity: 0.65;
    color: #ffffff;
    border-radius: 50%;
}

.meet .remotes-warpper .remotes .left .icon:hover,
.meet .remotes-warpper .remotes .right .icon:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.2, 1.2);
    transition: all 0.2s;
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}


.meet .remotes-warpper .remotes .menu {
    width: 320px;
    height: 240px;
    display: inline-block;

    transition: none;
}

.meet .remotes-warpper .remotes .menu:hover {
    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    width: 320px;
    height: 240px;
}

.meet .remotes-warpper .remotes .menu .operation {
    transition: none;
}

.meet .remotes-warpper .remotes .menu .description {
    line-height: 32px;
}

.meet .remotes-warpper .remotes .menu:hover .operation {
    opacity: 1;
    z-index: 500;
}


.meet .remotes-warpper .remotes .video {
    height: 240px;
    width: 320px;
}

.meet .remotes-warpper .remotes .slider {
    height: 240px;
}

.meet .remotes-warpper .remotes .slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    height: 240px;
}

.meet .remotes-warpper .remotes .slick-list {
    /* background-color: #2222227F; */
}

.meet .remotes-warpper .remotes .slick-arrow {
    opacity: 0;
    display: none!important;
}

.meet .remotes-warpper .remotes .slick-slide {
    display: inline-block;
    pointer-events: auto;
    text-align: center;
    height: 240px;
    width: 320px;
    line-height: 240px;
    overflow: hidden;
    background-color: #222222;
}
  
.meet .remotes-warpper .remotes .slick-track {
    height: 240px;
}

.meet .remotes-warpper .remotes .remote1,
.meet .remotes-warpper .remotes .remote2,
.meet .remotes-warpper .remotes .remote3,
.meet .remotes-warpper .remotes .remote4,
.meet .remotes-warpper .remotes .remote5,
.meet .remotes-warpper .remotes .remote6,
.meet .remotes-warpper .remotes .remote7,
.meet .remotes-warpper .remotes .remote8 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    width: 320px;
    height: 240px;

}

/* 浮动布局（视频为底） */
.meet .video-overlap {
    position: relative;
}

.meet .video-overlap .board {
    top: 4rem;
    right: 1rem;
    width: 320px;
    height: 240px;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .video-overlap .video {
    border: none;
}

.meet .video-overlap .remote0,
.meet .video-overlap .remote1,
.meet .video-overlap .remote2,
.meet .video-overlap .remote3,
.meet .video-overlap .remote4,
.meet .video-overlap .remote5,
.meet .video-overlap .remote6,
.meet .video-overlap .remote7,
.meet .video-overlap .remote8 {
    z-index: -1;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* 浮动布局（电子白板为底） */
.meet .board-overlap {
    position: absolute;
    left: 0;
    top: 64px;
}

.meet .board-overlap .operation {
    position: absolute;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.meet .board-overlap .operation .col {
    width: 58px;
    height: 51px;
    margin: 6px;
    padding-top: 4px;
}

.meet .board-overlap .operation .col:hover {
    background-color: #ffe58f;
    cursor: pointer;
}

.meet .board-overlap .operation .icon {
    font-size: 22px;
}

.meet .board-overlap .operation .description {
    font-size: 14px;
    line-height: 1.5;
    user-select: none;
}

.meet .board-overlap .board {
    z-index: -1;
}

.meet .board-overlap .board .show {
    opacity: 1;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .board-overlap .board .hidden {
    opacity: 0;
    height: 0;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.meet .board-overlap .remotes {
    position: absolute;
    top: 4rem;
    right: 1rem;

    width: 320px;
    height: 240px;
    z-index: 100;

    background: #222222;

    border-radius: 2px;
    overflow: hidden; 
}

.meet .board-overlap .remotes:hover {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
}

.meet .board-overlap .remotes:hover .icon {
    opacity: 1;
    z-index: 300;

    -webkit-transition:opacity .5s ease-in-out;
    -moz-transition:opacity .5s ease-in-out;
    -o-transition:opacity .5s ease-in-out;
    -ms-transition:opacity .5s ease-in-out;
    transition:opacity .5s ease-in-out;
}

/* 四分屏 */
.meet .video-split-4 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 50%;
}

.meet .video-split-4 .remote0,
.meet .video-split-4 .remote1,
.meet .video-split-4 .remote2,
.meet .video-split-4 .remote3,
.meet .video-split-4 .board {
    position: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    margin: 0 auto;
    vertical-align: middle;
}

.meet .video-split-4 .remote0 .obscuration,
.meet .video-split-4 .remote3 .obscuration  {
    background-color: #262626;
    border: 1px solid #ffffff7f;
}

.meet .video-split-4 .remote1 .obscuration,
.meet .video-split-4 .remote2 .obscuration  {
    background-color: #222222;
    border: 1px solid #ffffff7f;
}

.meet .video-split-4 .menu:hover{
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);

    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* 九分屏 */
.meet .video-split-9 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33.3%;
}

.meet .video-split-9 .remote0,
.meet .video-split-9 .remote1,
.meet .video-split-9 .remote2,
.meet .video-split-9 .remote3,
.meet .video-split-9 .remote4,
.meet .video-split-9 .remote5,
.meet .video-split-9 .remote6,
.meet .video-split-9 .remote7,
.meet .video-split-9 .remote8,
.meet .video-split-9 .board {
    position: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin: 0 auto;
    vertical-align: middle;
}

.meet .video-split-9 .remote0 .obscuration,
.meet .video-split-9 .remote2 .obscuration,
.meet .video-split-9 .remote4 .obscuration,
.meet .video-split-9 .remote6 .obscuration,
.meet .video-split-9 .remote8 .obscuration{
    background-color: #262626;
    border: 1px solid #ffffff7f;
}

.meet .video-split-9 .remote1 .obscuration,
.meet .video-split-9 .remote3 .obscuration,
.meet .video-split-9 .remote5 .obscuration,
.meet .video-split-9 .remote7 .obscuration {
    background-color: #222222;
    border: 1px solid #ffffff7f;
}

.meet .video-split-9 .menu:hover {
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);

    background-color: rgba(0,0,0,0.3);
    opacity: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}


.meet .footer {

}
@primary-color: #21f679;