.paragraph .title {

}

.paragraph .description {

}

.paragraph .description .label {
    /* padding-left: 0.5rem; */
    width: 5.5rem;
    display: inline-block;
} 

.paragraph .description .value {

}

.paragraph .description .operation .ant-btn {
    margin-left: 0.5rem;
    height: 0;
    padding: 0;
}

.sub-paragraph .title {
    /* padding-left: 0.5rem; */
}

.sub-paragraph .description {
    padding-left: 0.5rem;
}

.sub-paragraph .description .label {
    /* padding-left: 0.5rem; */
    width: 5rem;
    display: inline-block;
} 

.sub-paragraph .description .value {

}

.sub-paragraph .description .operation .ant-btn {
    margin-left: 1rem;
    height: 0;
    padding: 0;
}
