

.detail {
    .ant-modal-header {
        .title {
            .icon {
                margin-right: 1rem;
            }
        }
    }

    .ant-modal-body {
        .descriptions {
            .ant-descriptions-row {
                display: flex;
                align-items: center;
                height: 32px;

                .ant-descriptions-item-label {
                    width: 5rem;
                    align-self: center;
                }
            } 
        }
    }
    
}

@primary-color: #21f679;