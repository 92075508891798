.not-find-wrapper  {
    display: flex;
    align-items: center;
}
.not-find {
    margin: 0 auto;
}

.btn-development.ant-btn-background-ghost {
    box-shadow: none;
}
