@import '~antd/lib/style/themes/default.less';
.login {
    text-align: start;

    .show {
        opacity: 1!important;
        z-index: 999;
    }
    
    .hidden {
        opacity: 0!important;
        z-index: -1;
        display: none;
        box-shadow: none;
    }

    .background {
        opacity: 1;
        position: relative;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #096dd9;
        background-image: radial-gradient(circle, #b5f5ec, #096dd9); /* 标准的语法（必须放在最后） */
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        overflow: hidden;
    }

    .introduction  {
        .ant-typography ul {
            list-style-type: disc
        }
    }

    .header {
        height: 2rem;
        text-align: right;
    
        .version {
            font-weight: 500;
            padding: 0.25rem 1rem;
            cursor: default;
            user-select: none;
        }
    }

    .footer {
        display: flex;
        height: 2rem;
        align-items: center;
        justify-content: center;
        text-align: center;
    
        .copyright {
            font-weight: 500;
        }
    }

    .env {
        position: absolute;
        left: 50%;
        top: 1rem;
    }

    .setting-icon {
        padding-right: 16px;
        :hover {
            color: @blue-5;
        }
    }

    .control {
        padding: 0 12px;

        .login {
            position: relative;
            overflow: hidden;
            box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);

            .ant-tabs {
                .ant-tabs-nav {
                    margin: 0;
                }

                .ant-tabs-content-holder {
                    .ant-tabs-content {
                        margin-bottom: 16px;
                    }
                }
            }

        }
        .ant-drawer-body {

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .ant-drawer-footer {
            border: 0;

            .ant-btn {
                margin-left: 1rem;
            }
        }

        .tabs {
            background: #fff;

            .ant-tabs-top-bar {
                margin-bottom: 0;
            }
            .ant-tabs-extra-content {
                height: 64px;
                line-height: 64px;
            }
            .ant-tabs-nav-wrap {
                height: 64px;
            }
            .ant-tabs-tab {
                padding: 12px 24px;
                margin: 0 8px;
                height: 64px;
                display: inline-flex;
                align-items: center;
            }

            .ant-tabs-tabpane {
                justify-content: center;
                display: flex;
                min-height: 360px;
            }
        }

        .dialog {
            width: 100%;
            padding: 0 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .content {
                width: 100%;
            }
        }

        .setting {
            .ant-drawer-footer {
                text-align: right;
            }
        }

        .title {
            font-weight: 600;
            vertical-align: middle;
        }
        .logo {
            width: 18px;
            height: 18px;
            margin-right: 0.5rem;
        }

        .ant-card-head {
            border-bottom: 0;
        }

        .ant-card-body {
            padding: 12px 24px;
        }

        .content {
            .username, 
            .password {
                margin-bottom: 1rem;
            }

            .operations {
                margin: 2rem 0 1rem 0;
            }

            .title {
                margin-bottom: 0.5rem;
            }
            
            .ant-input-lg {
                font-size: 14px;
                padding: 0 0.5rem;
            }
            
            .login {
                margin: 2rem 0 1rem 0;
            }
        }

        .code {
            // height: 400px;
            transition: opacity 0.3s cubic-bezier(.7,.3,.1,1);
        }

        .profile {
            height: 242px;
            text-align: center;
            transition: opacity 0.3s cubic-bezier(.7,.3,.1,1);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        
            .name{
                font-size: 36px;
                font-weight: 500;
                display: inline-block;
                white-space: nowrap; 
                width: 100%; 
                overflow: hidden;
                text-overflow:ellipsis;
                max-width: 360px;
            }
        
            .status {
                margin: 1rem 0;
        
                .description{
                    margin-left: 1rem;
                    font-size: 14px;
                }
            }
        
            .avatar {
                border-radius: 2px;
            }
            
        }
    }
}

@primary-color: #21f679;