
@keyframes bulingbuling {
    from { opacity: 1.0; }
    50% { opacity: 0.1; }
    to { opacity: 1.0; }
}


.dot-success {
    background-color: #73d13d;
}

.dot-failed {
    background-color: #ff4d4f;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.tooltip-loudspeaker {

    .ant-tooltip-inner {
        min-width: 360px;
        background-color: rgb(255, 255, 255);
        color: rgba(0, 0, 0, 0.65);

        .title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .description {
                display: flex;
                justify-content: space-between;
                padding: 0 4px;
            }
        }
    }
    .ant-tooltip-arrow {
        &::before {
            background-color: rgb(255, 255, 255);
        }
    }

    .ant-tooltip-arrow-content {
        background-color: rgb(255, 255, 255);
    }
} 

.tooltip-normal .ant-tooltip-inner,
.tooltip-error .ant-tooltip-inner,
.tooltip-success .ant-tooltip-inner,
.tooltip-error-permission .ant-tooltip-inner {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.65);
}

.tooltip-normal .ant-tooltip-arrow::before,
.tooltip-error .ant-tooltip-arrow::before,
.tooltip-success .ant-tooltip-arrow::before,
.tooltip-error-permission .ant-tooltip-arrow::before {
    background-color: rgb(255, 255, 255);
}

.tooltip-normal .ant-tooltip-arrow-content,
.tooltip-error .ant-tooltip-arrow-content,
.tooltip-success .ant-tooltip-arrow-content,
.tooltip-error-permission .ant-tooltip-arrow-content {
    background-color: rgb(255, 255, 255);
}

.tooltip-error .ant-tooltip-inner {
    color: #ff4d4f;
}

.tooltip-success .ant-tooltip-inner {
    color: #73d13d;
}

.tooltip-normal .detail .ant-descriptions-row > td {
    padding-bottom: 0;
}

.tooltip-normal .detail .ant-descriptions-item-label {
    width: 7rem;
}

.status .tip {
    font-weight: 500;
}

.status .hidden {
    opacity: 0;
    height: 0;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.status .show {
    opacity: 1;
    height: 100%;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}


.status .profile,
.status .icon {
    align-self: center;
}

.status .profile .row {
    display: flex;
}

.status .icon-show {
    align-self: center;
    margin-right: 0.5rem;
    width: 100%;
    opacity: 1;
    z-index: 2;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.status .icon-hidden {
    align-self: center;
    width: 0;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.status .profile .name {
    display: flex;
    align-items: center;
}

.status .profile .name .avatar {
    margin-left: 0.5rem;
    height: 16px;
    border-radius: 2px;
}

.tooltip-error-permission {
    max-width: 600px!important;
}

.tooltip-error-permission .title .ant-descriptions-title {
    color: #ff4d4f;
    font-size: 14px;
}

.tooltip-error-permission .ant-descriptions-item {
    padding-bottom: 0.5rem;
    height: 2rem;
}

.tooltip-error-permission .ant-descriptions-item-label {
    width: 15%;
}

.tooltip-error-permission .ant-descriptions-item-content {
    width: 85%;
}

.tooltip-error-permission .ant-descriptions-item-content .help {
    padding: 0 0.5rem;
    height: 100%;
}

.drawer-header .ant-drawer-body,
.drawer-footer .ant-drawer-body {
    padding: 0 1rem;
    overflow: hidden;
}

.drawer-header .ant-drawer-content-wrapper,
.drawer-footer .ant-drawer-content-wrapper{
    box-shadow: none!important;
}

.drawer-header .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to bottom,rgba(0,0,0,.5),rgba(0,0,0,0));
}
.drawer-footer .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.5));
}

.drawer-header .content,
.drawer-footer .content {
    height: 64px;
}

.drawer-header .left .status {
    display: inline-flex;
    position: relative;
    /* margin-left: 1rem; */
    z-index: 100;
}

.drawer-header .left .status .dot {
    position: relative;
    display: inline-block;

    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}

.drawer-header .left .status .permission {
    position: relative;
    /* display: inline-block; */

    font-size: 24px;
    margin-right: 1rem;
    vertical-align: middle;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}


.drawer-header .left .status .permission-icon {
    position: relative;
    /* display: inline-block; */

    font-size: 24px;
    margin-right: 1rem;
    vertical-align: middle;
    animation: bulingbuling 600ms infinite;
    -webkit-animation: bulingbuling 600ms infinite;
}

.drawer-header .left .status .warning {
    position: relative;
    /* display: inline-block; */

    font-size: 24px;
    margin-right: 1rem;
    vertical-align: middle;
}

.drawer-header .middle .theme {
    margin: 0 auto;
    font-size: 22px;
    color: #fff;
    display: table;
    /* user-select: none;  */
}

.drawer-header .middle .timer {
    color: #fff;
    display: table;
    margin: 0 auto;
    user-select: none; 
}

.drawer-header .status .description {
    margin: 0 0.5rem;
    color: #fff;
    user-select: none; 
}

.drawer-header .right {
    /* display: contents; */
}

.drawer-header2 {
    position: absolute;
    width: 100%;
    height: 64px;
    z-index: 50;

    display: flex;
    justify-content: center;
}

.drawer-header2 .nickname {
    background-color: rgba(0, 0, 0, 0.45)!important;
    height: 36px;
    font-size: 22px;
    line-height: 30px;
    align-self: center;
    user-select: none;
    padding: 0 1rem;
}

.drawer-header2.hidden {
    height: 0!important;
    width: 100%!important;
}

.drawer-footer .icons .icon,
.drawer-header .right .icon {
    border-radius: 2px;
    font-size: 24px;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    text-align: right;
    float: right;
    padding: 8px;
    user-select: none;
}

.drawer-footer .icons .icon:hover,
.drawer-header .right .icon:hover {
    cursor: pointer;
    background-color: #ffffff7F;
    -webkit-transition:background-color .5s ease-in-out;
    -moz-transition:background-color .5s ease-in-out;
    -o-transition:background-color .5s ease-in-out;
    -ms-transition:background-color .5s ease-in-out;
    transition:background-color .5s ease-in-out;
}


.drawer-footer .icons {
    display: flex;
    text-align: center;
}

.drawer-footer .icons .main {
    align-items: baseline;
}

.drawer-footer .icons .main > svg {
    margin: 0 1rem;
    border-radius: 50%;
    font-size: 40px;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.drawer-footer .icons .main > svg:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

.drawer-footer .icons .local-view,
.drawer-footer .icons .board-view,
.drawer-footer .icons .board,
.drawer-footer .icons .backgroud-view,
.drawer-footer .icons .share-screen,
.drawer-footer .icons .layout-mode {
    display: flex;
    position: relative;
    margin-right: 0.5rem;
}

.drawer-footer {
    .icons {
        .offcut-none {
            margin: 0 18px;
            .icon {
                cursor: default;

                &:hover {
                    transform: none;
                    transition: none;
                    background-color: transparent;
                }

                & > svg:hover {
                    transform: none;
                    transition: none;
                }
            }
        }
    }
}  

.drawer-footer .icons .offcut-audio,
.drawer-footer .icons .offcut-video {
    display: flex;
    position: relative;
    margin: 0 1rem;
}

.drawer-footer .icons .offcut-audio .icon > svg:hover,
.drawer-footer .icons .offcut-video .icon > svg:hover {
    transform: scale(1.1, 1.1);
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    /* box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3); */
    /* border-radius: 50%; */
}

.drawer-footer .icons .offcut-video .icon,
.drawer-footer .icons .offcut-audio .icon {
    font-size: 36px;
    padding: 0 0.5rem 0 0;
    margin: 0 0;
    /* transition: none; */
    align-self: center;
}

.drawer-footer .icons .offcut-audio .icon:hover,
.drawer-footer .icons .offcut-video .icon:hover {
    background-color: transparent;
}

.drawer-footer .icons .local-view .control,
.drawer-footer .icons .board-view .control,
.drawer-footer .icons .offcut-video .control,
.drawer-footer .icons .offcut-audio .control,
.drawer-footer .icons .board .control,
.drawer-footer .icons .share-screen .control {
    position: absolute;
    bottom: 0;
    right: 0.25rem;
    font-size: 16px;
    height: 1.25rem;
    z-index: 2;
    pointer-events: none;
}

.drawer-footer .icons .board .control {
    top: 0.25rem;
    font-size: 10px;
    bottom: 50%;
    transform: translate(50%, 40%);
    right: 50%;
}

.drawer-footer .icons .share-screen .control {
    top: 0.15rem;
    font-size: 10px;
    bottom: 50%;
    transform: translate(50%, 40%);
    right: 50%;
}

.drawer-footer .icons .icon-right {
    display: flex;
    position: relative;
    margin: 0 1rem;
    float: right;
}


.drawer-footer .mid,
.drawer-footer .icons .mid {
    display: flex;
    justify-content: center;
}

.drawer-footer .right,
.drawer-footer .icons .right {
    display: block;
    float: right;
}

.drawer-footer .left,
.drawer-footer .icons .left {
    float: left;
}

.drawer-footer .icons .selected {
    border: 1px solid transparent;
    background-color: #ffffff7F;
    border-radius: 2px;
}

.drawer-footer .icons .unselect {
    border: 1px dashed #ffffff7F;
    border-radius: 2px;
}

/* .drawer-right,
.ant-drawer-right.ant-drawer-open.drawer-right {
    display: flex;
}

.drawer-right .ant-drawer-content-wrapper {
    height: 80%!important;
    align-self: center;
}

.ant-drawer-right.ant-drawer-open.drawer-right .ant-drawer-content-wrapper {
    height: 80%!important;
    align-self: center;
    margin-right: 1rem;
} */

.drawer-right .ant-drawer-header,
.ant-drawer-right.ant-drawer-open.drawer-right .ant-drawer-header {
    padding: 16px;
    background: transparent;
}

.drawer-right .ant-drawer-body,
.ant-drawer-right.ant-drawer-open.drawer-right .ant-drawer-body {
    padding: 0;
}

@primary-color: #21f679;